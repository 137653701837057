import React from 'react';
import EventListing from '../components/contentBlocks/EventListing';
import Layout from '../components/Layout';

const Events = ({ path }) => (
  <Layout title="Events" path={path}>
    <EventListing />
  </Layout>
);

export default Events;
